/* eslint-disable react/no-danger */
import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

// import "@stylesPages/About.scss";

// import Platform from "@icons/platform.svg";
// import Desktop from "@icons/Desktop.svg";
// import Advisor from "@icons/Advisor.svg";
// import LongTerm from "@icons/LongTerm.svg";
// import Experience from "@icons/Experience.svg";
// import Customer from "@icons/Customer.svg";

// import { ContactForm } from "@containers";
// import { Footer, Header, SEO } from "@components";

import "../styles/pages/About.scss";

import Platform from "../assets/images/icons/platform.svg";
import Desktop from "../assets/images/icons/Desktop.svg";
import Advisor from "../assets/images/icons/Advisor.svg";
import LongTerm from "../assets/images/icons/LongTerm.svg";
import Experience from "../assets/images/icons/Experience.svg";
import Customer from "../assets/images/icons/Customer.svg";

import { Footer, Header, SEO } from "../components";

const About = () => {
  const { t } = useTranslation("about");

  return (
    <div className="about">
      <Header />
      <section className="about__section about__section--top">
        <div className="about__top-left">
          <h1 className="about__top-title">{t("about-title")}</h1>
          <p className="about__top-copy" dangerouslySetInnerHTML={{ __html: t("about-copy-top") }}></p>
          <h4 className="about__top-text">{t("about-text-top")}</h4>
        </div>
        <div className="about__top-right">
          <div className="about__cards-content">
            <article className="about__top-card">
              <h1 className="about__card-title">{t("card-title-1")}</h1>
              <p className="about__text">{t("card-text-1")}</p>
            </article>
            <article className="about__top-card">
              <h1 className="about__card-title">{t("card-title-2")}</h1>
              <p className="about__text">{t("card-text-2")}</p>
            </article>
          </div>
          <article className="about__card-big">
            <h1 className="about__card-title">{t("card-title-3")}</h1>
            <ul className="about__card-list">
              <li
                className="about__card-list-item about__card-list-item--black"
                dangerouslySetInnerHTML={{ __html: t("card-text-list-1") }}
              />
              <li
                className="about__card-list-item about__card-list-item--black"
                dangerouslySetInnerHTML={{ __html: t("card-text-list-2") }}
              />
              <li
                className="about__card-list-item about__card-list-item--black"
                dangerouslySetInnerHTML={{ __html: t("card-text-list-3") }}
              />
              <li
                className="about__card-list-item about__card-list-item--black"
                dangerouslySetInnerHTML={{ __html: t("card-text-list-4") }}
              />
              <li
                className="about__card-list-item about__card-list-item--black"
                dangerouslySetInnerHTML={{ __html: t("card-text-list-5") }}
              />
              <li
                className="about__card-list-item about__card-list-item--black"
                dangerouslySetInnerHTML={{ __html: t("card-text-list-6") }}
              />
            </ul>
          </article>
        </div>
      </section>
      <div className="about__banner">
        <h3 className="about__banner-title" dangerouslySetInnerHTML={{ __html: t("banner-title") }}></h3>
        <p className="about__text about__text--banner" dangerouslySetInnerHTML={{ __html: t("banner-text") }}></p>
      </div>
      <section className="about__section about__section--column">
        <h2 className="about__title">{t("icons-title")}</h2>
        <div className="about__icons-content">
          <article className="about__icons-box">
            <img className="about__icons-img" src={Platform} alt="" />
            <p className="about__text about__text--icons">{t("icons-txt-1")}</p>
          </article>
          <article className="about__icons-box">
            <img className="about__icons-img" src={Desktop} alt="" />
            <p className="about__text about__text--icons">{t("icons-txt-2")}</p>
          </article>
          <article className="about__icons-box">
            <img className="about__icons-img" src={Advisor} alt="" />
            <p className="about__text about__text--icons">{t("icons-txt-3")}</p>
          </article>
          <article className="about__icons-box">
            <img className="about__icons-img" src={LongTerm} alt="" />
            <p className="about__text about__text--icons">{t("icons-txt-4")}</p>
          </article>
          <article className="about__icons-box">
            <img className="about__icons-img" src={Experience} alt="" />
            <p className="about__text about__text--icons">{t("icons-txt-5")}</p>
          </article>
          <article className="about__icons-box">
            <img className="about__icons-img" src={Customer} alt="" />
            <p className="about__text about__text--icons">{t("icons-txt-6")}</p>
          </article>
        </div>
      </section>
      <section className="about__section about__section--column">
        <h2 className="about__title about__title--confidence">{t("confidence-title")}</h2>
        <div className="about__confidence-content">
          <p className="about__text about__text--confidence">{t("confidence-text")}</p>
          <article className="about__card-big about__card-big--confidence">
            <ul className="about__card-list">
              <li className="about__card-list-item">
                {t("confidence-card-text-1")}
              </li>
              <li className="about__card-list-item">
                {t("confidence-card-text-2")}
              </li>
              <li className="about__card-list-item">
                {t("confidence-card-text-3")}
              </li>
              <li className="about__card-list-item">
                {t("confidence-card-text-4")}
              </li>
              <li className="about__card-list-item">
                {t("confidence-card-text-5")}
              </li>
              <li className="about__card-list-item">
                {t("confidence-card-text-6")}
              </li>
            </ul>
          </article>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default About;

export const Head = ({ data: { locales } }) => {
  const about = locales.edges.find(({ node }) => (node.ns === "about"));

  const { "about-title": titleSeo } = about ? JSON.parse(about.node.data) : {
    "about-title": ""
  };

  return (
    <SEO title={titleSeo} />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;